<template>
  <div class="about">
    <el-button @click="AddClassify()">新增</el-button>
    <el-table border :data="ClassifyDatas" style="width: 100%">
      <el-table-column label="名称" width="180">
        <template #default="scope">
          <span>{{ scope.row.Name }}</span>
        </template>
      </el-table-column>
      <el-table-column label="开始时间" width="200">
        <template #default="scope">
          <span>{{ scope.row.BeginDate }}</span>
        </template>
      </el-table-column>
      <el-table-column label="结束时间" width="200">
        <template #default="scope">
          <span>{{ scope.row.EndDate }}</span>
        </template>
      </el-table-column>

      <el-table-column label="简介">
        <template #default="scope">
          <div class="name-wrapper">
            {{ scope.row.Description }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="操作" fixed="right" width="400">
        <template #default="scope">
          <el-button
            type="button"
            size="small"
            @click="EditWindow(scope.$index, scope.row)"
            >编辑</el-button
          >
          <el-button
            size="small"
            type="danger"
            @click="CategoryDelete(scope.$index, scope.row)"
            >删除</el-button
          >
          <!-- <el-button
            size="small"
            @click="AccountDelete(scope.$index, scope.row)"
            >详情页管理</el-button
          >
          <el-button
            size="small"
            @click="AccountDelete(scope.$index, scope.row)"
            >管理商品</el-button
          > -->
        </template>
      </el-table-column>
    </el-table>
  </div>

  <!-- 修改新增分类 -->
  <el-dialog
    v-model="EditOrAddWindow"
    :title="IsAdd ? '新增' : '修改'"
    width="90%"
    :before-close="EditOrAddWindowClose"
  >
    <el-form ref="form" label-position="right" label-width="100px">
      <el-form-item label="名称">
        <el-input v-model="ClassifyModel.Name"></el-input>
      </el-form-item>

      <el-form-item label="税率">
        <el-input-number v-model="ClassifyModel.TaxRate" :min="0" :max="100" />
      </el-form-item>
      <el-form-item label="排序">
        <el-input-number v-model="ClassifyModel.Sort" />
      </el-form-item>
      <el-form-item label="开始时间">
        <el-date-picker
          format="YYYY-MM-DD HH:mm:ss"
          value-format="YYYY/MM/DD HH:mm:ss"
          v-model="ClassifyModel.BeginDate"
          type="datetime"
          placeholder="Select date and time"
          :shortcuts="shortcuts"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="结束时间">
        <el-date-picker
          v-model="ClassifyModel.EndDate"
          format="YYYY-MM-DD HH:mm:ss"
          value-format="YYYY/MM/DD HH:mm:ss"
          type="datetime"
          placeholder="Select date and time"
          :shortcuts="shortcuts"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="简介">
        <el-input
          v-model="ClassifyModel.Description"
          type="textarea"
        ></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="EditOrAddWindowClose">关闭</el-button>
        <el-button type="primary" @click="EditOrAddSave()">确定</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script  >
import axios from "../../../commons/AxiosMethod.js"
import commons from "../../../commons/commons.js"

import { ElMessageBox } from "element-plus"
export default {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  data () {
    return {
      IsAdd: false,
      EditOrAddWindow: false,
      ClassifyDatas: [],
      ClassifyModel: {}
    }
  },
  name: "App",

  components: {},
  mounted () {
    this.GetCategory()
  },
  methods: {
    EditOrAddWindowClose () {
      this.EditOrAddWindow = false
    },
    EditWindow (index, item) {
      this.EditOrAddWindow = true
      this.ClassifyModel = item
    },
    CategoryDelete (index, item) {
      commons.confirmDelete(item.Name, () => {
        axios.apiMethod(
          "/api/Category/DeleteCategory?Id=" + item.Id,
          "get",
          {},
          (result) => {
            if (result.Code == 200) {
              this.EditOrAddWindow = false
              this.GetCategory()
            } else {
              ElMessageBox({
                message: result.FullMsg,
                type: "error"
              })
            }
          }
        )
      })

      // ElMessageBox.confirm("确定要删除<span class='optName'>"+item.Name+"</span>吗?",  '提示', {dangerouslyUseHTMLString: true})
      //   .then(() => {
      //     axios.apiMethod(
      //       "/api/Category/DeleteCategory?Id=" + item.Id,
      //       "get",
      //       {},
      //       (result) => {
      //         if (result.Code == 200) {
      //           this.EditOrAddWindow = false;
      //           this.GetCategory();
      //         } else {
      //           ElMessageBox({
      //             message: result.FullMsg,
      //             type: "error",
      //           });
      //         }
      //       }
      //     );
      //   })
      //   .catch(() => {
      //     // catch error
      //   });
    },
    AddClassify () {
      this.ClassifyModel = {
        TaxRate: 10,
        Sort: 0
      }
      this.EditOrAddWindow = true
      this.IsAdd = true
    },
    GetCategory () {
      var param = {}
      axios.apiMethod("/api/Category/GetCategory", "post", param, (result) => {
        if (result.Code == 200) {
          this.ClassifyDatas = result.Data
        } else {
          ElMessageBox({
            message: result.FullMsg,
            type: "error"
          })
        }
      })
    },
    EditOrAddSave () {
      var param = {}
      axios.apiMethod(
        "/api/Category/AddCategory",
        "post",
        this.ClassifyModel,
        (result) => {
          if (result.Code == 200) {
            this.GetCategory()
            this.EditOrAddWindowClose()
          } else {
            ElMessageBox({
              message: result.FullMsg,
              type: "error"
            })
          }
        }
      )
    }
  }
}
</script>

<style scoped>
.manage_title {
  height: 50px;
  background-color: rgb(67, 74, 80);
}
</style>
